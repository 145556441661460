



































































































































































































































































































































































































































































































































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';
import InfoDialog from '@/components/app/InfoDialog.vue';
import MaterialAlert from '@/components/MaterialAlert.vue';
import RegistrationWizard from '@/components/app/RegistrationWizard.vue';

// --- Models ---
import { LoggedInUser, User } from '@/models/User.model';

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';

// --- Third Party ---
import axios from 'axios';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue';

export interface Phone {
  number: any;
  regionCode: string;
  valid: boolean;
  country: any;
}

export interface ErrorFields {
  proofOfAddress: boolean;
  companyRegistration: boolean;
  idDocument: boolean;
  directorIdDocument: boolean;
}

export interface ValidationRules {
  required: any;
  optionalFilesize1Mb: any;
  optionalFilesize5Mb: any;
  optionalImageFileType: any;
  optionalPdfFileType: any;
  numbersOnly: any;
  idNumber: any;
  vatNumber: any;
  companyRegistrationNumber: any;
}

@Component({
  components: {
    InfoDialog,
    MaterialAlert,
    RegistrationWizard,
    VueTelInputVuetify,
  },
})
export default class CompleteRegistrationPage extends Vue {

  tab: number = 0;
  tabs: string[] = ['Personal'];
  title: string = 'Complete Registration';
  createUpdateDelay: boolean = false;

  infoDialogAvailable: boolean = false;
  showInfoDialog: boolean = false;
  infoDialogTitle: string = '';
  infoDialogContent: string = '';

  personalFormValid: boolean = false;

  uploadFiles: File[] = [];
  /*
    avatar = uploadFiles[0]
    proofOfAddress = uploadFiles[1]
    id = uploadFiles[2]
    companyRegistration = uploadFiles[5]
    directorId = uploadFiles[6]
  */
  avatarUrl: string = '';
  errorFields: ErrorFields = {
    proofOfAddress: false,
    companyRegistration: false,
    idDocument: false,
    directorIdDocument: false,
  };

  doNotValidateAfterRegistrationTypeChanged: boolean = true;
  cellphoneErrorMessage: string = '';
  companyContactErrorMessage: string = '';

  sameIDNumber: boolean = false;

  clickedItem: User = {
    firstName: '',
    lastName: '',
    email: '',
    registrationTypeId: 0,
    registrationType: '',
    userTypeId: 0,
    userType: '',
    avatarFilename: '',
    cellphoneCountryRegion: 'ZA',
    cellphoneCountryDialCode: '',
    cellphoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    postalCode: '',
    country: 'South Africa',
    regionId: 0,
    region: '',
    addressFilename: '',
    idNumber: '',
    idFilename: '',
    hasUpcomingShoots: false,
  };

  doUpdate: boolean = false;

  rules: ValidationRules = {
    required: (value: string) => !!value || '',
    optionalFilesize1Mb: (value: File) => value ? (!value || value.size < 1048576 || 'File size should be less than 1 MB') : true,
    optionalFilesize5Mb: (value: File) => value ? (!value || value.size < 5242880 || 'File size should be less than 5 MB') : true,
    optionalImageFileType: (value: File) => value ? (value.type === 'image/jpeg' || value.type === 'image/png' || 'Invalid file type. Must be an image') : true,
    optionalPdfFileType: (value: File) => value ? (value.type === 'application/pdf' || 'Invalid file type. Must be a PDF') : true,
    numbersOnly: (value: string) => (/^\d+$/.test(value)) || 'Invalid character',
    idNumber: (value: string) => /^(?=.*[a-zA-Z0-9- ])[a-zA-Z0-9- ]+$/.test(value) || 'Invalid character',
    vatNumber: (value: string) => value ? /^[4][0-9]{9}$/.test(value) || 'Invalid South African VAT Number' : true,
    companyRegistrationNumber: (value: string) => /^[0-9]{4}\/[0-9]{6}\/[0-9]{2}$/.test(value) || 'Invalid South African Company Registration Number',
  };

  // #region Functions to assist validation rules
    validateCellphoneInput(formattedNumber: any, phone: Phone) {
      if (this.doNotValidateAfterRegistrationTypeChanged) {
        // do nothing?
        phone.valid = true;
        this.doNotValidateAfterRegistrationTypeChanged = false;
      } else {
        this.cellphoneErrorMessage = phone.valid ? '' : 'Invalid Phone Number';

        if (phone.valid) {
          this.clickedItem.cellphoneCountryRegion = phone.regionCode;
          this.clickedItem.cellphoneCountryDialCode = phone.country.dialCode;
          this.clickedItem.cellphoneNumber = (phone.number.input as string).split(' ').join('');
        }
      }
   }

   validateCompanyContactInput(formattedNumber: any, phone: Phone) {
      if (this.doNotValidateAfterRegistrationTypeChanged) {
        // do nothing?
        phone.valid = true;
        this.doNotValidateAfterRegistrationTypeChanged = false;
      } else {
        this.companyContactErrorMessage = phone.valid ? '' : 'Invalid Phone Number';

        if (phone.valid) {
          this.clickedItem.companyContactCountryRegion = phone.regionCode;
          this.clickedItem.companyContactCountryDialCode = phone.country.dialCode;
          this.clickedItem.companyContactNumber = (phone.number.input as string).split(' ').join('');
        }
      }
   }
  // #endregion

  // region Functions to display data on page load
    async beforeMount() {
      // this is to check if the AppBar should display the Edit button or not
      this.$root.$emit('update:appBar', this.$route.name);

      await AppService.listRegistrationTypes(false);
      await AppService.listRegions(false);
      
      // when we arrive here, we need to go and fetch the Vendor's data 
      // so that we can tell how far they are thru the Registration process
      const loggedInUser: LoggedInUser = this.$store.getters['users/loggedInUser'];

      switch (loggedInUser.registrationStatusId) {
        case 1: // NEW
          // this should never happen
          // redirect to login
          window.location.href = this.$store.getters['app/urls'].login;
          return;

        case 2: // VERIFIED
          // verified email address, start on first Tab
          this.tab = 0;

          // pre-select first Region if there is only 1
          if (this.$store.getters['app/regions'].length < 2) {
            this.clickedItem.regionId = this.$store.getters['app/regions'][0].id;
          }

          return;
        
        case 3: // HAS_PERSONAL
        case 4: // HAS_INSURANCE
          // this should never happen as Producers go straight from Personal Details to AWAITING_APPROVAL
          return;

        case 5: // AWAITING_APPROVAL
          if (this.$router.currentRoute.name === 'Edit Registration') {
            // If they're on the Edit page, let them stay here and get their info to update
            await this.getLoggedInProducer();
            this.tab = 0;
          } else {
            // otherwise redirect to Awaiting Approval page
            this.$router.push({ name: 'Awaiting Approval'});
          }
          return;

        case 6: // APPROVED
          // redirect to Dashboard
          return;
      }
    }

    async getLoggedInProducer() {
      try {
        const response = await AuthService.getProfile();

        if (response && response.length > 0) {
          // something broke
          AppService.errorHandler(response);
        } else {
          this.doUpdate = true;
          this.clickedItem = this.$store.getters['users/loggedInUser'].profile;
          if (this.clickedItem.directorIdNumber && this.clickedItem.directorIdNumber === this.clickedItem.idNumber) {
            this.sameIDNumber = true;
          }
          this.showAvatarPreview();
        }

      } catch (error) {
        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('CompleteRegistration.vue - getLoggedInProducer - 528 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }
      }
    }

    changeRegistrationType() {
      // they've switched from Individual to Company or Company to Individual, what info should we keep?

      this.clickedItem.companyName = undefined;

      this.clickedItem.vatNumber = '';
      this.clickedItem.companyRegistrationNumber = undefined;
      this.clickedItem.companyRegistrationFilename = undefined;
      // companyRegistration = uploadFiles[5]
      if (this.uploadFiles[5]) {
        delete this.uploadFiles[5];
      }

      this.clickedItem.avatarFilename = '';
      // avatar = uploadFiles[0]
      if (this.uploadFiles[0]) {
        delete this.uploadFiles[0];
      }
      this.showAvatarPreview();

      // keep individual cellphone number

      this.clickedItem.companyContactCountryRegion = '';
      this.clickedItem.companyContactCountryDialCode = '';
      this.clickedItem.companyContactNumber = '';
      this.doNotValidateAfterRegistrationTypeChanged = true;
      this.cellphoneErrorMessage = '';
      this.companyContactErrorMessage = '';
      
      // keep address

      // keep individual id number & upload

      this.sameIDNumber = false;
      this.clickedItem.directorIdNumber = '';
      this.clickedItem.directorIdFilename = '';
      // directorId = uploadFiles[6]
      if (this.uploadFiles[6]) {
        delete this.uploadFiles[6];
      }

      this.$nextTick(() => {
        if (this.$refs.personalForm) {
          (this.$refs.personalForm as any).resetValidation();
        }
      });
    }

    resetIDFields() {
      this.clickedItem.directorIdNumber = '';
      this.clickedItem.directorIdFilename = '';
      // directorId = uploadFiles[6]
      if (this.uploadFiles[6]) {
        delete this.uploadFiles[6];
      }
    }

    getDocumentUploadMessage( 
      uploadFilename: keyof User, 
      fileIndex: number, 
      filesizeValidation: keyof ValidationRules, 
      errorField: keyof ErrorFields, 
      alreadyUploadedMessage: string,
    ) {
      let isError: boolean = false;
      let message: string = '';

      // First, we need to figure out if we have a validation error 
      if (this.clickedItem[uploadFilename]) {
        message = alreadyUploadedMessage;

        // all our validation is optional
        if ((typeof this.rules[filesizeValidation](this.uploadFiles[fileIndex]) !== 'boolean') ) {
          message = this.rules[filesizeValidation](this.uploadFiles[fileIndex]) + '';
          isError = true;
        }

        if ((typeof this.rules.optionalPdfFileType(this.uploadFiles[fileIndex]) !== 'boolean') ) {
          message = this.rules.optionalPdfFileType(this.uploadFiles[fileIndex]) + '';
          isError = true;
        }

      } else {
        // all our validation is required
        if (this.uploadFiles[fileIndex]) {
          if ((typeof this.rules[filesizeValidation](this.uploadFiles[fileIndex]) !== 'boolean') ) {
            message = this.rules[filesizeValidation](this.uploadFiles[fileIndex]) + '';
            isError = true;
          }

          if ((typeof this.rules.optionalPdfFileType(this.uploadFiles[fileIndex]) !== 'boolean') ) {
            message = this.rules.optionalPdfFileType(this.uploadFiles[fileIndex]) + '';
            isError = true;
          }
        } else {
          // it is requiredisError = true;
          isError = true;
        }
      }

      this.errorFields[errorField] = isError;
      return message;
    }
  // #endregion

  // #region Functions required for the Registration Wizard

    get availableSteps() {
      const steps = [0];

      if (this.personalFormValid) {
        steps.push(1);
      }

      return steps;
    }

    get scope() {
      if (this.tab === 0) {
        return 'personal';
      }
    }

    async RegistrationWizard_goNext() {
      if (this.scope === 'personal') {
        await this.doCreateUpdate();
      }
    }

    showAvatarPreview() {
      // avatar = uploadFiles[0]
      if (this.uploadFiles && this.uploadFiles[0]) {
        this.avatarUrl = URL.createObjectURL(this.uploadFiles[0]);
      } else if (this.clickedItem.avatarFilename) {
        // files for test data are in a local folder so they don't conflict with LIVE data
        this.avatarUrl = 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'profile_pictures/' + this.clickedItem.avatarFilename;  
      } else {
        this.avatarUrl = '';
        if (this.uploadFiles[0]) {
          delete this.uploadFiles[0];
        }
      }
    }

    async doCreateUpdate() {
      this.createUpdateDelay = true;
      if (!this.doUpdate) {
        await this.doCreateProducer();
      } else {
        await this.doUpdateProducer();
      }
    }

    async doCreateProducer() {
      try {
        const formData = new FormData();

        if (this.clickedItem.registrationTypeId === 2 && this.sameIDNumber) {
          this.clickedItem.directorIdNumber = this.clickedItem.idNumber;
          this.uploadFiles[6] = this.uploadFiles[2];
        }

        formData.append('registrationTypeId', '' + this.clickedItem.registrationTypeId);
        formData.append('companyName', '' + this.clickedItem.companyName);
        formData.append('vatNumber', '' + this.clickedItem.vatNumber);
        formData.append('companyRegistrationNumber', '' + this.clickedItem.companyRegistrationNumber); 
        formData.append('cellphoneCountryRegion', '' + this.clickedItem.cellphoneCountryRegion);
        formData.append('cellphoneCountryDialCode', '' + this.clickedItem.cellphoneCountryDialCode);
        formData.append('cellphoneNumber', '' + this.clickedItem.cellphoneNumber);
        formData.append('companyContactCountryRegion', '' + this.clickedItem.companyContactCountryRegion);
        formData.append('companyContactCountryDialCode', '' + this.clickedItem.companyContactCountryDialCode);
        formData.append('companyContactNumber', '' + this.clickedItem.companyContactNumber);
        formData.append('addressLine1', '' + this.clickedItem.addressLine1);
        formData.append('addressLine2', '' + this.clickedItem.addressLine2);
        formData.append('addressLine3', '' + this.clickedItem.addressLine3);
        formData.append('city', '' + this.clickedItem.city);
        formData.append('regionId', '' + this.clickedItem.regionId);
        formData.append('postalCode', '' + this.clickedItem.postalCode);
        formData.append('country', '' + this.clickedItem.country);        
        formData.append('idNumber', '' + this.clickedItem.idNumber);
        formData.append('directorIdNumber', '' + this.clickedItem.directorIdNumber);

        /*
          avatar = uploadFiles[0]
          proofOfAddress = uploadFiles[1]
          id = uploadFiles[2]
          companyRegistration = uploadFiles[5]
          directorId = uploadFiles[6]
        */

        formData.append('avatarFile', this.uploadFiles[0]);
        formData.append('proofOfAddressFile', this.uploadFiles[1]);
        formData.append('idDocumentFile', this.uploadFiles[2]);
        formData.append('companyRegistrationFile', this.uploadFiles[5]);
        formData.append('directorIdDocumentFile', this.uploadFiles[6]);

        const response = await AuthService.createProducer( formData );

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler('Saved');
            // now we take them to the "waiting for approval" screen
            this.$router.push({ name: 'Awaiting Approval'});
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('CompleteRegistration.vue - doCreateProducer - 658 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.createUpdateDelay = false;
      }
    }

    async doUpdateProducer() {
      try {
        const formData = new FormData();
        let tmpSameIDNumber: boolean = false;
        
        if (this.clickedItem.registrationTypeId === 2 && this.sameIDNumber) {
          this.clickedItem.directorIdNumber = this.clickedItem.idNumber;
          
          // check if they have uploaded a new id doc
          if (this.uploadFiles && this.uploadFiles[2]) {
            this.uploadFiles[6] = this.uploadFiles[2];
          } else if (this.clickedItem.idFilename) {
            tmpSameIDNumber = true;
          }
        }

        formData.append('registrationTypeId', '' + this.clickedItem.registrationTypeId);
        formData.append('companyName', '' + this.clickedItem.companyName);
        formData.append('vatNumber', '' + this.clickedItem.vatNumber);
        formData.append('companyRegistrationNumber', '' + this.clickedItem.companyRegistrationNumber);
        formData.append('cellphoneCountryRegion', '' + this.clickedItem.cellphoneCountryRegion);
        formData.append('cellphoneCountryDialCode', '' + this.clickedItem.cellphoneCountryDialCode);
        formData.append('cellphoneNumber', '' + this.clickedItem.cellphoneNumber);
        formData.append('companyContactCountryRegion', '' + this.clickedItem.companyContactCountryRegion);
        formData.append('companyContactCountryDialCode', '' + this.clickedItem.companyContactCountryDialCode);
        formData.append('companyContactNumber', '' + this.clickedItem.companyContactNumber);
        formData.append('addressLine1', '' + this.clickedItem.addressLine1);
        formData.append('addressLine2', '' + this.clickedItem.addressLine2);
        formData.append('addressLine3', '' + this.clickedItem.addressLine3);
        formData.append('city', '' + this.clickedItem.city);
        formData.append('postalCode', '' + this.clickedItem.postalCode);
        formData.append('country', '' + this.clickedItem.country);
        formData.append('regionId', '' + this.clickedItem.regionId);
        formData.append('idNumber', '' + this.clickedItem.idNumber);
        formData.append('directorIdNumber', '' + this.clickedItem.directorIdNumber);
        formData.append('sameIdNumber', '' + tmpSameIDNumber);

        /*
          avatar = uploadFiles[0]
          proofOfAddress = uploadFiles[1]
          id = uploadFiles[2]
          insurance = uploadFiles[3]
          proofOfBank = uploadFiles[4]
          companyRegistration = uploadFiles[5]
          directorId = uploadFiles[6]
        */

        if (this.uploadFiles && this.uploadFiles[0]) {
          formData.append('avatarFile', this.uploadFiles[0]);
        }
        if (this.uploadFiles && this.uploadFiles[1]) {
          formData.append('proofOfAddressFile', this.uploadFiles[1]);
        }
        if (this.uploadFiles && this.uploadFiles[2]) {
          formData.append('idDocumentFile', this.uploadFiles[2]);
        }
        if (this.uploadFiles && this.uploadFiles[5]) {
          formData.append('companyRegistrationFile', this.uploadFiles[5]);
        }
        if (this.uploadFiles && this.uploadFiles[6]) {
          formData.append('directorIdDocumentFile', this.uploadFiles[6]);
        }

        const response = await AuthService.updateProducer( formData );

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler('Saved');
            // now we take them to the "waiting for approval" screen
            this.$router.push({ name: 'Awaiting Approval'});
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('CompleteRegistration.vue - doUpdateProducer - 714 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.createUpdateDelay = false;
      }
    }
  // #endregion  

  // #region Functions required for the Information Dialog
    InformationDialog_cancel() {
      this.showInfoDialog = false;
      this.infoDialogTitle = '';
      this.infoDialogContent = '';

      setTimeout(() => {
        this.infoDialogAvailable = false;
      }, 200);
    }

    // TODO: could we make these generic by sending in the param?
    launchRegistrationTypeInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].registrationType.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].registrationType.content;
      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchCellphoneInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].cellphone.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].cellphone.content;

      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchCompanyContactInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].contactNumber.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].contactNumber.content;

      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchAddressInfoDialog() {
      if (this.clickedItem.registrationTypeId === 1) {
        this.infoDialogTitle = this.$store.getters['app/infoDialogs'].address.title;
        this.infoDialogContent = this.$store.getters['app/infoDialogs'].address.content;
      } else {
        this.infoDialogTitle = this.$store.getters['app/infoDialogs'].companyAddress.title;
        this.infoDialogContent = this.$store.getters['app/infoDialogs'].companyAddress.content;
      }
      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchVATNumberInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].vatNumber.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].vatNumber.content;
      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchCompanyRegistrationNumberInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].companyRegistrationNumber.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].companyRegistrationNumber.content;
      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchIDNumberInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].idNumber.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].idNumber.content;

      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchDirectorIDNumberInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].directorIdNumber.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].directorIdNumber.content;

      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }
  // #endregion

}
