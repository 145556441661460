<template>
  <v-card
    class="v-card--wizard"
    elevation="3"
    :width="getWidthForBreakpoint($vuetify.breakpoint.name)"
  > 
    <v-card-title 
      class="justify-center text-h4 text-lg-h2 font-weight-light pt-5"
      style="word-break: normal"
      v-html="title"
    >
    </v-card-title>

    <v-card-text class="mb-2">
      Hi {{ displayName }}, thanks for signing up with <span class="eazi--text">eazi</span><span class="gear--text">gear</span>. We just need a little more information from you before you can get started.
    </v-card-text>

    <v-tabs
      ref="tabs"
      v-model="internalValue" 
      background-color="primary lighten-5"
      show-arrows
      center-active
      grow
      slider-size="52"
    >
      <v-tabs-slider
        class="elevation-3"
        color="primary"
      />

      <v-tab
        v-for="(item, i) in items"
        :key="i"
        :ripple="false"
        :disabled="internalValue != i"
      >
        {{ item }}
      </v-tab>
    </v-tabs>

    <div class="my-6" />

    <v-card-text>
      <v-tabs-items 
        v-model="internalValue"
        touchless
      >
        <slot />
      </v-tabs-items>
    </v-card-text>

    <v-card-actions class="pb-4 pa-4" style="padding-top: 0px !important">
      <v-row class="pa-4">
        <v-btn
          v-if="internalValue !== 0"
          :disabled="loading"
          class="white--text mb-2 ml-auto"
          color="#777B7E"
          :width="$store.getters['app/maxWidthButton']($vuetify.breakpoint.name, ['xs'])"
          @click="goBack()"
        >
          Previous
        </v-btn>

        <v-spacer/>

        <v-btn
          v-if="(internalValue === items.length - 1) ? true : 'Next'"
          :disabled="!availableSteps.includes(internalValue + 1) || loading"
          color="primary"
          class="mb-2 ml-auto"
          :width="$store.getters['app/maxWidthButton']($vuetify.breakpoint.name, ['xs'])"
          @click="goNext()"
          :loading="loading"
        >
          {{ internalValue === items.length - 1 ? 'Save' : 'Next' }}

          <span
            slot="loader"
          >
            <v-progress-circular
              indeterminate
              :size="20"
              color="white"
            >
            </v-progress-circular>
          </span>
        </v-btn>
      </v-row>
    </v-card-actions>
    <div
      style="margin-left: 3px"
      class="required-instruction"
    >
      * Required Field
    </div> 
  </v-card>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable';

  export default {
    name: 'CustomWizard',

    mixins: [Proxyable],

    props: {
      title: {
        type: String,
        default: '',
      },
      availableSteps: {
        type: Array,
        default: () => ([]),
      },
      items: {
        type: Array,
        default: () => ([]),
      },
      loading: {
        type: Boolean,
        default: false,
      },
      displayName: {
        type: String,
        default: '',
      },
    },

    methods: {
      goBack() {
        this.$emit('click:prev');
      },
      
      goNext() {
        this.$emit('click:next');
      },

      getWidthForBreakpoint(breakpoint) {
        switch (breakpoint) {
          case 'xl':
          case 'lg':
            return '70%';

          case 'md':
            return '80%';
          default:
            return '90%';
        }
      },
    },
  };
</script>

<style lang="sass">
.v-card--wizard
  .v-tabs-bar
    height: 48px

  .v-slide-group__wrapper
    contain: none

    .v-tabs-slider-wrapper
      contain: initial
      z-index: 0
  
</style>